const _ = require(`underscore`);
const __request = require(`./__request/__request_contentType_json`);

// 查询手机号是否已被注册使用接口文档地址：http://219.128.52.2:9999/doc.html#/yinzhilv_travel/user-controller/checkPhoneVerifyUsingPOST
const web_common_user_checkPhone = (pParameter) => {

  if (!pParameter) pParameter = {};

  const p = {
    method: 'post',
    urlSuffix: '/web/common/user/checkPhone',
    data: pParameter,
  };
  return new Promise((reslove) => {
    __request(p).then(data => {
        if (data.code === '000000') {
            reslove(data.flag)
        }
    })
  })

};
module.exports = web_common_user_checkPhone;
