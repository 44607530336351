const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);
const recordCount = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/web/common/userSupplier/details',
        data: data
    }
    return __request(pParameter)
}
export default recordCount

