//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import web_common_source_getPermissions from "@/lib/common-service/web_common_source_getPermissions";
import redirect_to_user_back_home from "@/lib/common-service/redirect_to_user_back_home";
import redirect_to_login_page from "@/lib/common-service/redirect_to_login_page";
import web_common_tourOrder_getTravelAccount from "@/lib/data-service/default/web_common_tourOrder_getTravelAccount";
import logout from "@/lib/data-service/default/logout";
import Q from "q";

export default {
  data() {
    return {
      data_web_common_source_getPermissions: {
        userName: ``
      },
      data_web_common_tourOrder_getTravelAccount: {
        travelAccountManager: ``,
        phone: ``,
        fromCity: ""
      }
    };
  },
  methods: {
    __btn_admin_click() {
      const __this = this;
      redirect_to_user_back_home();
    },
    __btn_logout_click() {
      const __this = this;
      Q.when()
        .then(function() {
          return logout();
        })
        .then(function(data) {
          // 删除12306的账号本地缓存
          localStorage.removeItem("userInfoVo");
          localStorage.removeItem("trainLoginParams");

          redirect_to_login_page();
        })
        .catch(function(ex) {
          console.error(ex);
          if (ex) {
            if (ex.stack) {
              console.error(ex.stack);
            }
          }
          return Q.reject(ex);
        });
    }
  },
  created() {
    const __this = this;
    web_common_source_getPermissions().then(function(data) {
      __this.data_web_common_source_getPermissions = data;
    });
    web_common_tourOrder_getTravelAccount().then(function(data) {
      __this.data_web_common_tourOrder_getTravelAccount = data;
      __this.data_web_common_tourOrder_getTravelAccount.fromCity =
        data.cityName;
    });
  },
  activated() {},
  watch: {},
  computed: {}
};
