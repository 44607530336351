const Q = require('q');
const __request = require(`./__request/__request_contentType_form`);
const web_common_kaptcha_processor = require(`yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/web_common_kaptcha_processor`);

const web_common_kaptcha = function (pParameter) {
  if (!pParameter) pParameter = {};

  // 接口文档地址：http://192.168.0.12:8080/doc.html#/所有接口/login-controller/initKaptchaUsingPOST

  const uuid = pParameter.uuid || ``;

  return Q.when()
    .then(function () {
      return __request({
        urlSuffix: 'web/common/kaptcha',
        data: {
          uuid: uuid,
        },
        check_token: false,
      });
    })
    .then(function (data) {
      const img = data.img;
      if (!img) {
        const msg = 'web_common_kaptcha parameter error, code=101';
        console.error(msg);
        return Q.reject({msg: msg});
      }

      const uuid = data.uuid;
      if (!uuid) {
        const msg = 'web_common_kaptcha parameter error, code=102';
        console.error(msg);
        return Q.reject({msg: msg});
      }

      const d = {};
      d.uuid = uuid;

      web_common_kaptcha_processor.set({
        data: d,
      });

      return Q.resolve(data);
    })
    .catch(function (ex) {
      console.error(ex);
      if (ex) {
        if (ex.stack) {
          console.error(ex.stack);
        }
      }
      return Q.reject(ex);
    })
    ;

};

module.exports = web_common_kaptcha;
