const __request = require(`./__request`);
/**
 * 供应商注册
 */
export default function (val) {
  var pParameter = {}
  pParameter.method = "POST"
  pParameter.urlSuffix = "/web/common/userSupplier/register"
  pParameter.data = val
  pParameter.check_token = false;
  return new Promise(function (resolve, reject) {
    resolve(__request(pParameter))
  })
}
