//
//
//
//
//
//
//
//
//

import Header from '@/page/supplier/component/header/1.0.1/index.vue'
import Footer from '@/page/distributor/component/basic-layout/1.0.4/component/footer/index.vue'
export default {
    data (){
        return{


        }
    },
    components:{Header,Footer}
}
