const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request`);

// 验证原始密码接口文档地址：http://219.128.52.2:9999/doc.html#/所有接口/user-controller/getUserPasswordVerifyUsingPOST
const web_common_user_vueCheckUserName = (pParameter) => {

  if (!pParameter) pParameter = {};

  const p = {
    method: 'post',
    urlSuffix: '/web/common/user/vueCheckUserName',
    data: pParameter,

  };
  return Q.when()
    .then(function () {
      return __request(p);
    })
    .then(function (data) {
      return Q.resolve(data);
    })
    .catch(function (ex) {
      console.error(ex);
      if (ex) {
        if (ex.stack) {
          console.error(ex.stack);
        }
      }
      return Q.reject(ex);
    })
    ;

};
module.exports = web_common_user_vueCheckUserName;
