import _ from 'underscore';

import register from "@/lib/data-service/default/web_common_userSupplier_register";
import getInlandAreaChildListByParentIdResponse from "@/lib/data-service/default/web_common_area_getInlandAreaChildListByParentIdResponse";
import kaptcha from "@/lib/data-service/default/web_common_kaptcha";
import phoneRegister from "@/lib/data-service/default/web_common_register";
import vueCheckUserName from "@/lib/data-service/default/web_common_user_vueCheckUserName";
import web_common_user_checkPhone from "@/lib/data-service/default/web_common_user_checkPhone";

import { STATUS } from "@/lib/deprecated/utils/status";
import get_url_prefix from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/get_url_prefix";
import liability from "@/page/distributor/other/aboutUs/text/liability";
import knowledge from "@/page/distributor/other/aboutUs/text/knowledge";

const SUCCESS_CODE = STATUS.success;
import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client";
import userSupplier_details from "@/lib/data-service/default/web_common_userSupplier_details";
import updateSupplier from "@/lib/data-service/default/web_common_userSupplier_updateSupplier";
import verification from "@/lib/deprecated/utils/verification/1.0.3";

export default {
    data() {
        return {
            formData: {
                applicationType: "1",
                userName: "",
                password: "",
                phone: "",
                smsCode: "",
                airEmail: "",
                airLinkman: "",
                airPhone: "",
                airQq: "",
                areaCity: "",
                areaCityName: "",
                areaProvince: "",
                areaProvinceName: "",
                areaRegion: "",
                areaRegionName: "",
                brandLogo: "",
                brandName: "",
                businessLicenseNum: "",
                businessLicensePic: "",
                commonSeal: "",
                companyAddress: "",
                companyId: 0,
                companyName: "",
                financeFax: "",
                guarantee: "",
                iataProve: "",
                idCard: "",
                idCardFront: "",
                idCardReverse: "",
                travelBusinessLicenseNum: "",
                travelBusinessLicensePic: "",
                travelEmail: "",
                travelLinkman: "",
                travelPhone: "",
                travelQq: "",
                areaCitys: [],
            },
            ossClient: new OssClient(),
            protocolText: {
                show: false,
                show1: false,
                text: liability,
                text1: knowledge,
                title: "网站声明",
                title1: "知识产权声明",
            },
            authCode: {
                url: "",
                value: "",
                show: false,
            }, //图片验证
            getCodePhone: {
                staut: false,
                show: false,
                num: 60,
                text: "获取短信验证码",
            }, //验证短信按钮
            siteList: {}, //地址
            agreeStatu: false,
            fileListImg: {
                businessLicensePic: [],
                idCardFront: [],
                idCardReverse: [],
                travelBusinessLicensePic: [],
                guarantee: [],
                brandLogo: [],
                iataProve: [],
            },
            imageUrl: {
                businessLicensePic: '',
                idCardFront: '',
                idCardReverse: '',
                iataProve: '',
                guarantee: '',
                travelBusinessLicensePic: '',
                commonSeal: '',
                brandLogo: '',
            },
            uploadLoading: {
                businessLicensePic: false,
                idCardFront: false,
                idCardReverse: false,
                iataProve: false,
                guarantee: false,
                travelBusinessLicensePic: false,
                commonSeal: false,
                brandLogo: false,
            },
            selectCityStatu: false,
            rules: {
                //验证
                userName: [
                    {
                        validator: verification.checkUser,
                        trigger: "blur",
                        required: true,
                    },
                ],
                password: [
                    {
                        validator: verification.checkPassWord,
                        trigger: "blur",
                        required: true,
                    },
                ],
                passwordAgain: [
                    {
                        validator: verification.validatePass,
                        trigger: "blur",
                        required: true,
                    },
                ],
                phone: [
                    {
                        validator: verification.checkPhone,
                        trigger: "blur",
                        required: true,
                    },
                ],
                smsCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
                companyName: [
                    { required: true, message: "请输入公司名称", trigger: "blur" },
                ],
                companyAddress: [
                    { required: true, message: "请输入公司地址", trigger: "blur" },
                ],
                businessLicenseNum: [
                    {
                        validator: verification.checkBusinessLicense,
                        trigger: ["blur", "chang"],
                        required: true,
                    },
                ],
                businessLicensePic: [
                    {
                        validator: verification.checkBusinessLicensePic,
                        required: true,
                    },
                ],
                airLinkman: [
                    { required: true, message: "请输入联系人", trigger: "blur" },
                ],
                airPhone: [
                    {
                        validator: verification.checkPhone,
                        trigger: "blur",
                        required: true,
                    },
                ],
                travelLinkman: [
                    { required: true, message: "请输入联系人", trigger: "blur" },
                ],
                travelPhone: [
                    {
                        validator: verification.checkPhone,
                        trigger: "blur",
                        required: true,
                    },
                ],
                areaCitys: [
                    { required: true, message: "请选择城市", trigger: ["blur", "chang"] },
                ],
                idCardReverse: [
                    { validator: verification.checkIdCard, required: true },
                ],
                guarantee: [{ required: true, validator: verification.checkGuarantee }],
                iataProve: [{ required: true, validator: verification.checkIataProve }],
                travelBusinessLicenseNum: [
                    {
                        validator: verification.checkTravelBusinessLicense,
                        required: true,
                        trigger: "blur",
                    },
                ],
                travelBusinessLicensePic: [
                    {
                        validator: verification.checkTravelBusinessLicensePic,
                        required: true,
                    },
                ],
                idCard: [
                    { required: true, validator: verification.checkId, trigger: "blur" },
                ],
            },
        };
    },
    watch: {
        formData: {
            handler(newVal) {
                verification.formData = newVal;
            },
            deep: true,
        },
    },
    mounted() {
        this._getInlandArea("100000", (res) => {
            this.$set(this.siteList, "area", res);
        });
        verification.self = this;
        if (this.$route.query.type) {
            let id = this.$route.query.id;
            this.getInfo(id);
        }
    },
    methods: {
        getInfo(supplierId) {
            userSupplier_details({ supplierId }).then((res) => {
                let data = Object.assign(this.formData, res.result);
                let imgList = [
                    "businessLicensePic",
                    "idCardFront",
                    "idCardReverse",
                    "travelBusinessLicensePic",
                    "guarantee",
                    "brandLogo",
                    "iataProve",
                ];
                imgList.map((name) => {
                    if (data[name]) {
                        this.fileListImg[name].push({
                            name: name,
                            url: data[name],
                        });
                        document.querySelector(`#${name} .el-upload`).style.display =
                            "none";
                    }
                });
                data.areaRegions = data.areaRegionName;
                data.areaProvinces = data.areaProvinceName;
                data.areaCitys = data.areaCityName;
                this.$nextTick(() => {
                    this.formData = data;
                });
            });
        },
        //获取产品图片上传的地址
        uploadChange(file, name, from) {
            this.$set(this.uploadLoading, name, true);
            this.ossClient
                .multipartUpload({
                    file: file.file,
                })
                .then((res) => {
                    this.formData[name] = res.url;
                    this.$set(this.imageUrl, name, res.url);
                    this.$message({ type: "success", message: "上传图片成功" });
                    this.checkUpload(name, from);
                })
                .catch(() => {
                    this.$message.error('上传图片失败');
                })
                .finally(() => {
                    this.$set(this.uploadLoading, name, false);
                });
        },
        checkUpload(name, from) {
            if (from) {
                name === "businessLicensePic"
                    ? this.$refs[from].validateField("businessLicenseNum")
                    : "";
                name === "idCardFront" || "idCardReverse"
                    ? this.$refs[from].validateField("idCardReverse")
                    : "";
                name === "iataProve" ? this.$refs[from].validateField("iataProve") : "";
                name === "guarantee" ? this.$refs[from].validateField("guarantee") : "";
                name === "travelBusinessLicensePic"
                    ? this.$refs[from].validateField("travelBusinessLicenseNum")
                    : "";
            }
        },

        //检验img格式
        detectionImg(file) {
            var reg2 = /^(\s|\S)+(jpg|png|bmp|jpeg)+$/;
            const isLt2M = file.size / 1024 < 10240;
            if (!reg2.test(file.name)) {
                this.$message({ type: "warning", message: "图片格式上传错误！" });
                return false;
            }
            if (!isLt2M) {
                this.$message.error("上传图片大小不能超过 10M!");
                return false;
            }
        },
        //处理产品图片的删除
        handleRemoveImg(file, name, from) {
            this.formData[name] = "";
            this.fileListImg[name] = [];
            this.checkUpload(name, from);
            document.querySelector(`#${name} .el-upload`).style.display = "block";
        },
        //检查用户名
        queryUserName() {
            const name = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/;
            if (!this.formData.userName) {
                return;
            } else {
                if (!name.test(this.formData.userName)) return;
            }
            vueCheckUserName({ userName: this.formData.userName }).then((res) => {
                if (res.code === SUCCESS_CODE) {
                    !res.flag
                        ? this.$message({ type: "warning", message: "用户已注册" })
                        : "";
                }
            });
        },
        //获取短信
        getNote() {
            let phoneReg = /^1[3|4|5|7|8][0-9]{9}$/;
            if (!this.formData.phone) {
                return this.$message({ type: "warning", message: "请输入手机号码" });
            } else {
                if (!phoneReg.test(this.formData.phone)) {
                    return this.$message({
                        type: "warning",
                        message: "电话号码格式不正确",
                    });
                }
            }
            this.authCode.value = "";
            this.authCode.show = true;
            kaptcha().then((res) => {
                if (res.code === SUCCESS_CODE) {
                    this.authCode.url = res.img;
                    this.authCode.uuid = res.uuid;
                    this.$refs.authCode.focus();
                }
            });
        },
        //手机验证码
        getPhoneAuthCode() {
            let authCode = this.authCode;
            if (authCode.value) {
                authCode.show = false;
                let data = {
                    phoneNumber: this.formData.phone,
                    verificationCode: authCode.value,
                    roleType: 2,
                    uuid: authCode.uuid,
                };
                this.getCodePhone.statu = true;
                phoneRegister(data).then(
                    (res) => {
                        if (res.code === SUCCESS_CODE) {
                            this.getCodePhone.statu = false;
                            this.getCodePhone.show = true;
                            this.getCodePhone.text = "重新发送";
                            let codeNmu = setInterval(() => {
                                this.getCodePhone.num--;
                                if (this.getCodePhone.num < 1) {
                                    clearInterval(codeNmu);
                                    this.getCodePhone.text = "获取短信验证码";
                                    this.getCodePhone.show = false;
                                    this.getCodePhone.num = 60;
                                }
                            }, 1000);
                        }
                    },
                    (error) => {
                        this.getCodePhone.statu = false;
                        this.$forceUpdate();
                    }
                );
            }
        },
        //提交表单
        submitForm() {
            let data = this.formData;
            let formRuleName = [];
            let _this = this;

            this.formData.applicationType == "1"
                ? (formRuleName = ["userForm", "companyForm", "airForm", "airUserForm"])
                : this.formData.applicationType == "2"
                ? (formRuleName = [
                    "userForm",
                    "companyForm",
                    "travelForm",
                    "travelUserForm",
                ])
                : (formRuleName = [
                    "userForm",
                    "companyForm",
                    "airForm",
                    "travelForm",
                    "airUserForm",
                    "travelUserForm",
                ]);
            let statu = formRuleName.map((item) => {
                let _s = Boolean;
                this.$refs[item].validate((valid) => {
                    if (valid) {
                        _s = true;
                        if (this.$route.query.type) {
                            if (data.areaCitys && this.selectCityStatu) {
                                data.areaRegion = data.areaRegions[0];
                                data.areaProvince = data.areaProvinces[0];
                                data.areaCity = data.areaCitys[0];
                                data.areaRegionName = data.areaRegions[1];
                                data.areaProvinceName = data.areaProvinces[1];
                                data.areaCityName = data.areaCitys[1];
                            }
                        } else {
                            if (data.areaCitys) {
                                data.areaRegion = data.areaRegions[0];
                                data.areaProvince = data.areaProvinces[0];
                                data.areaCity = data.areaCitys[0];
                                data.areaRegionName = data.areaRegions[1];
                                data.areaProvinceName = data.areaProvinces[1];
                                data.areaCityName = data.areaCitys[1];
                            }
                        }
                    } else {
                        _s = false;
                    }
                });
                return _s;
            });
            statu = statu.every((item) => item === true);
            if (statu) {
                if (!this.agreeStatu)
                    return this.$message({
                        type: "warning",
                        message: "请勾选服务协议！",
                    });
                data.idCard = data.idCard.replace(/\s+/g, "");
                data.businessLicenseNum = data.businessLicenseNum.replace(/\s+/g, "");
                this.$route.query.id ? (data.companyId = this.$route.query.id) : "";
                this.$route.query.type
                    ? updateSupplier(data).then((res) => {
                        _this.$router.push({
                            name: "register-success",
                            query: { type: "4" },
                        });
                    })
                    : register(data).then((res) => {
                        _this.$router.push({
                            name: "register-success",
                            query: { type: "4" },
                        });
                        // let url = get_url_prefix({environment: global.appConfig.environment, appKey: 'default'})
                        // window.location.href = url
                    });
            } else {
                this.$nextTick(() => {
                    let firstEle = document.getElementsByClassName(
                        "el-form-item__error"
                    )[0];
                    document.documentElement.scrollTop =
                        document.documentElement.scrollTop +
                        firstEle.getBoundingClientRect().top -
                        firstEle.parentElement.offsetHeight -
                        18;
                });
                return false;
            }
        },
        //获取城市列表
        _getInlandArea(id, callback) {
            getInlandAreaChildListByParentIdResponse({ id: id }).then((res) => {
                callback(res.areaTreeResult);
            });
        },
        //选中大区
        _getArea(val) {
            this.selectCityStatu = true;
            this._getInlandArea(val[0], (res) => {
                this.formData.areaProvinces ? (this.formData.areaProvinces = "") : "";
                this.formData.areaCitys ? (this.formData.areaCitys = "") : "";
                this.$set(this.siteList, "province", res);
                this.$set(this.siteList, "city", []);
            });
        },
        //选中省份
        _getProvince(val) {
            this._getInlandArea(val[0], (res) => {
                this.formData.areaCitys ? (this.formData.areaCitys = "") : "";
                this.$nextTick(() => {
                    this.$set(this.siteList, "city", res);
                });
            });
        },
    },
};