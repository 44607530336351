import extend from 'extend';
import Q from 'q';
import _ from 'underscore';
// import moment from 'moment';
import web_common_checkToken
    from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/web_common_checkToken';
import HeadContent from "@/page/supplier/component/header/1.0.2/component/headContent/index.vue";

export default {
    data() {
        return {
            list: [
                { text: '分销商注册', name: 'distributor-registration' },
                { text: '供应商注册', name: 'supplier-registration' },
            ],
            navShow: false
        }
    },
    methods: {
        __btn_home_click() {
            window.location.href = '/'
        }
    },
    components: { HeadContent },
    mounted() {
        const __this = this;

        web_common_checkToken().then(
            function (res) {
                __this.navShow = true
            },
            function () {
                __this.navShow = false
            }
        )
    }
}
