const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request`);

// 接口文档地址：http://192.168.0.15:8080/doc.html#/所有接口/send-sms-message-and-validation/validationUsingPOST
const web_common_register = (pParameter) => {
  if (!pParameter) pParameter = {};

  const phoneNumber = pParameter.phoneNumber || ``;
  const verificationCode = pParameter.verificationCode || ``;
  const roleType = pParameter.roleType || ``;
  const uuid = pParameter.uuid || ``;
  const registerSts = pParameter.registerSts || ``;

  const p = {
    method: 'post',
    urlSuffix: 'web/common/register',
    data: {
      phoneNumber,
      verificationCode,
      roleType,
      uuid,
      registerSts
    },

  };
  return Q.when()
    .then(function () {
      return __request(p);
    })
    .then(function (data) {
      return Q.resolve(data);
    })
    .catch(function (ex) {
      console.error(ex);
      if (ex) {
        if (ex.stack) {
          console.error(ex.stack);
        }
      }
      return Q.reject(ex);
    })
    ;

};
module.exports = web_common_register;
